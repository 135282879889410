import React,{useState} from 'react'
import styled from 'styled-components'
import Logo from '../components/Logo'
import Instagram from '../assets/Instagram.png';
import OpenSea from '../assets/OpenSea.png';
import Twitter from '../assets/Twitter.png';
import Discord from '../assets/Discord.png';

const Section = styled.section`
              width: 100vw;
              background-color: #142c8e;
              `
const NavBar = styled.nav`
              display: flex;
              align-items: center;
              width: 85%;
              height: 50px;
              margin-left: 15%;
              justify-content: space-evenly;
              margin:  auto;
              .mobile{
                display: none;
              }
              @media (max-width: 64em) {
              .desktop{
                display: none;
              }
              .mobile{
                display: inline-block;
              }}`

const Menu = styled.ul`
      display: flex;
      justify-content: flex-end;
      align-items: center;
      list-style:none;

      @media (max-width: 64em) {
      position: fixed;
      top: ${props => props.theme.navHeight};
      left: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      height: ${props => `calc(100vh - 0)`};
      z-index:50;
      background-color: ${props => `rgba(${props.theme.bodyRgba},0.88)`};
      backdrop-filter: blur(2px);
      transform: ${props => props.click ? 'translateY(0)' : `translateY(1000%)`};
      transition: all 0.3s ease;
      flex-direction: column;
      justify-content: center;
      touch-action: none;
}

`
const MenuItem = styled.li`
        margin: 0 1rem;
        color: #fff;
        cursor: pointer;
        font-size: ${props => props.theme.h7};
        &::after{
          content: ' ';
          display: block;
          width: 0%;
          height: 2px;
          background: ${props => props.theme.text};
          transition: width 0.3s ease;
        }
        &:hover::after{
          width: 100%;
        }
        @media (max-width: 64em) {
        margin: 0.5rem 0;
        height: 10%;
        font-size: ${props => props.theme.h5};

        &::after{
          display: none;
        }  }
        `
const HamburgerMenu = styled.span`
          width:  ${props => props.click ? '2rem' : '1.5rem'};
          height: 3px;
          background: #fff;
          top: 1.5rem;
          left: 93%;
          position: absolute;
          transform: ${props => props.click ? 'translateX(-50%) rotate(90deg)' : 'translateX(-50%) rotate(0)'  };
          display: none;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s ease;
          @media (max-width: 64em) {
          display: flex;
          }
          &::after, &::before{
            content: ' ';
            width:  ${props => props.click ? '1rem' : '1.5rem'};
            height: 2px;
            right: ${props => props.click ? '-2px' : '0'};
            background: ${props => props.theme.text};
            position: absolute;
            transition: all 0.3s ease;
          }
          &::after{
            top: ${props => props.click ? '0.3rem' : '0.5rem'};
            transform: ${props => props.click ? 'rotate(-40deg)' : 'rotate(0)'  };
          }
          &::before{
            bottom: ${props => props.click ? '0.3rem' : '0.5rem'};
            transform: ${props => props.click ? 'rotate(40deg)' : 'rotate(0)'  };
          }         
           `
const IconList = styled.div`
           display: flex;
           align-items: center;
           &>*{
             padding-right: 0.5rem;
             transition: all 0.2s ease;
             &:hover{
               transform: scale(1.2);
             }
           }
        `

const Navigation = () => {
const [click, setClick] = useState(false);
const scrollTo = (id) => {

let element = document.getElementById(id);

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  })

  setClick(!click);
}

  return (
    
    <Section id="navigation">
      <NavBar>
        <Logo />
        <HamburgerMenu  click={click}  onClick={() => setClick(!click)}>
          &nbsp;
        </HamburgerMenu>
            <Menu click={click}>
              {/* <MenuItem onClick={() => scrollTo('home')}  >Home</MenuItem> */}
                  <MenuItem onClick={() => scrollTo('roadmap')}  >Mission</MenuItem>
                  <MenuItem onClick={() => scrollTo('sneakpeeek')}  >Sneak Peek</MenuItem>
                  {/* <MenuItem onClick={() => scrollTo('home')}  >Staking</MenuItem> */}
                  {/* <MenuItem onClick={() => scrollTo('home')}  >Documents</MenuItem> */}
                  <MenuItem onClick={() => scrollTo('team')}  >Team</MenuItem>
                  <MenuItem onClick={() => scrollTo('faq')}  >FAQ</MenuItem>
            <div className="mobile">
            <IconList>
                <a href="http://Opensea.io" target='_blank'>
                <img  width={30} height={30} src={OpenSea} alt="hunter" />
                </a>
                <a href="http://Discord.com" target='_blank'>
                <img  width={30} height={30}src={Discord} alt="hunter" />
                </a>
                <a href="http://Discord.com" target='_blank'>
                <img     width={30} height={30}src={Twitter} alt="hunter" />
                </a>
                <a href="http://Discord.com" target='_blank'>
                <img    width={30} height={30} src={Instagram} alt="hunter" />
                </a>
         </IconList>
            </div>
            </Menu>

            <div className="desktop">
            <IconList>
                <a href="http://Opensea.io" target='_blank'>
                <img  width={30} height={30} src={OpenSea} alt="hunter" />
                </a>
                <a href="http://Discord.com" target='_blank'>
                <img  width={30} height={30}src={Discord} alt="hunter" />
                </a>
                <a href="https://twitter.com/darkhuntersnft" target='_blank'>
                <img     width={30} height={30}src={Twitter} alt="hunter" />
                </a>
                <a href="https://www.instagram.com/Darkhuntersnft/" target='_blank'>
                <img    width={30} height={30} src={Instagram} alt="hunter" />
                </a>
         </IconList>
            </div>

      </NavBar>
    </Section>
  )
}

export default Navigation