import React, { useState } from 'react';
import styled from 'styled-components'
import bg from '../assets/FAQ/Stars-Background-FAQ.png';


 
import logo from '../assets/NFTs/Sneak-peeks.png'
import NFT1 from '../assets/NFTs/NFT1.png'
import NFT2 from '../assets/NFTs/NFT2.gif'
import NFT3 from '../assets/NFTs/NFT3.png'
import NFT4 from '../assets/NFTs/NFT4.gif'
import NFT6 from '../assets/NFTs/NFT6.png'
import NFT7 from '../assets/NFTs/NFT7.png'
import NFT8 from '../assets/NFTs/NFT8.gif'
import NFT9 from '../assets/NFTs/NFT9.png'

const Section = styled.section`
            min-height: 100vh;
            background-image: url(${bg});
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            @media (max-width: 70em){
              min-height: auto;
              background-size: cover;

            }
`

const Container = styled.div`
              width: 85%;
              margin: 1rem auto;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              flex-wrap: wrap;
              flex-direction: row;

              @media (max-width: 64em){
              width: 80%;
              }
              @media (max-width: 48em){
              width: 90%;
              justify-content: center;
              padding-top: 6%;

              }
              `
              const Item = styled.div`
              width: calc(20rem - 4vw);
              padding: 1rem 0;
              margin: 2rem 0.1rem;
              flex-direction: row;
              position: relative;
              
              z-index:5;
              &:hover{
                img{
                  transform: translateY(-2rem) scale(1.2);
                }
              }
              @media (max-width: 30em){
              width: 50vw;
              margin: 1rem 0.1rem;
              width: calc(10rem - 4vw);
              }
              @media (max-width: 700PX){
              width: 50vw;
              margin: 0rem 1.1rem;
              width: calc(10rem - 4vw);
              }
  
              `
  
  
  const Logo = styled.img`
     padding: 22PX;

     @media (max-width: 48em){
              height:20px;
              width: auto;
              padding: 2px;
              }

  `
  const Image = styled.img`
   width: 300px;
   height: 300px;
   padding: 1%;
   border-radius: 82px;
   &:hover{
    transform:  translateY(-2rem) scale(  1.8 );
     transition: transform 330ms ease-in-out;
    }
            @media (max-width: 64em){
              width: 200px;
              }
              @media (max-width: 48em){
              width: 130px;
              height: 130px;
              justify-content: center;
              padding: 4%;
              border-radius: 25px;
              }

  `
const Nfts = () => {


  return (
    

      <Section id="sneakpeeek">
              <Logo className={'logoTeam'} style={{padding:83}} src={logo}/> 

        <Container>

      <Image src={NFT1} alt="DARKHUNTERS" /> 
      <Image src={NFT2} alt="DARKHUNTERS" /> 
      <Image src={NFT3} alt="DARKHUNTERS" /> 
      <Image   src={NFT4} alt="DARKHUNTERS" /> 
      <Image   src={NFT6} alt="DARKHUNTERS" /> 
      <Image   src={NFT7} alt="DARKHUNTERS" /> 
      <Image   src={NFT8} alt="DARKHUNTERS" /> 
      <Image   src={NFT9} alt="DARKHUNTERS" /> 
      {/* <Image   src={NFT10} alt="DARKHUNTERS" />  */}

        </Container>
    </Section>
  )
}

export default Nfts



