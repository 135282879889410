
import React, { useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../components/Accordion';
import bg from '../assets/FAQ/Stars-Background-FAQ.png';
import logo from '../assets/FAQ/FAQ.png';


const Section = styled.section`
              min-height: 100vh;
              height: auto;
              width: 100vw;
              background-image: url(${bg});
              background-size: cover;
              position: relative;
              color: ${(props) => props.theme.body};
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
`

const Container = styled.div`
                width: 75%;
                margin: 2rem auto;
                display: flex;
                
                justify-content: center;
                align-content: center;
                backdrop-filter: blur(1px);
                @media (max-width: 64em){
                  width: 80%;
                  }
                  @media (max-width: 48em){
                  width: 90%;
                  flex-direction: column;

                  &>*:last-child{
                    &>*:first-child{

                    margin-top: 0;
                }

                  }
                  }
`
const Box = styled.div`
          width: 45%;
          @media (max-width: 64em){
            align-self: center;
            }
            @media (max-width: 48em){
            width: 90%;
            }

`

const Faq = () => {
const ref = useRef(null);

  return (
    <Section ref={ref} id="faq" >
    <img className={'logoFAQ'}  style={{padding:83}}  src={logo}/>
    <Container className='Faq'>

          <Box>

            <Accordion  title="- Supply? " >
            5k
            </Accordion>

            <Accordion title="- What network?" >
            Ethereum and contract ERC721A 
            </Accordion>

            <Accordion  title="- Mint price?" >
           Whitelist: 0.0 ETH and Public Sale: 0.00 ETH
            </Accordion>

          <Accordion title="- Why should I Mint a Dark Hunter NFT?" >
          As previous mentioned, we’re a group of art enthusiasts. Only mint if you vibe with our artwork  and community. 
          </Accordion>

          <Accordion title="- Where to mint?" > 
          Follow our official Twitter and active notifications to get updated on our official links.          </Accordion>

          </Box>
              </Container>
              </Section>
            )
          }

export default Faq