import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import { ThemeProvider } from "styled-components";
import {Faq, Team,Home,Roadmap,Banner,Navigation,Nfts } from "./pages/";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <main className="main">
      <GlobalStyles />
      <ThemeProvider theme={light}>
        {/* <Suspense fallback={<Loading />}> */}
          <Navigation />
          <Home />
          <Roadmap />
          <Nfts/>
          <Team />
          <Faq />
          <Banner />
          <ScrollToTop />{" "}
        {/* </Suspense> */}
      </ThemeProvider>
    </main>
  );
}

export default App;
