// This file contains variables for different themes

export const light = {
    
    body:"#202020",
    text:'#ffffff', 
    maincolor: '#0b1b4d',
    bodyRgba : "4, 0, 33",
    textRgba:"32,32,32",
    faq:"#7ba89e",

    carouselColor: '#EEEDDE',

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',
    fontButton:'0.875em',
    h1:'48px',
    h2:'36px',
    h3:'30px',
    h4:'24px',
    h5:'20px',
    h6:'18px',
    h7:'16px',
    h8:'14px',
    h8:'12px',
    h9:'10px',
    h10:'8px',


    Height: '22rem',
    navHeight: '3.2rem',
}


export const dark = {
    
    body:"#202020",
    text: "#fff",
    maincolor:'#0b1b4d',
    bodyRgba :"32,32,32" ,
    textRgba: "255, 255, 255",

    carouselColor: '#EEEDDE',

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',
    h1:'48px',
    h2:'36px',
    h3:'30px',
    h4:'24px',
    h5:'20px',
    h6:'18px',
    h7:'16px',
    h8:'14px',
    h8:'12px',
    h9:'10px',
    h10:'8px',
    fontButton:'0.875em',


    navHeight: '5rem',
}