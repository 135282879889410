import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import ImageOne from '../parallax/ImageOne';

const Section = styled.section`
            min-height: ${props => `calc(100vh - ${props.theme.navHeight})`   };
            width: 100vw;
            position: relative;
            `
const Home = () => {
  return (
    <Section id="home">
     <ImageOne/>
         </Section>
  )
}
export default Home