import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import img1 from '../assets/logo.png';

const Logodiv = styled.div`

transition: all 0.2s ease;
&:hover{
    transform: scale(1.1);
}
@media (max-width: 64em){
margin-right: 68%;
}
`
const Logo = () => {
  return (
    <Logodiv>
        <Link to="/">
        <img width={150} height={33}  src={img1} alt="The Weirdos" />
        </Link>
    </Logodiv>
  )
}

export default Logo