import React, { lazy, Suspense } from 'react'
import styled from 'styled-components';
import logo from '../assets/Team/TEAM.png';
import bg from '../assets/Team/Planet-Background-Team.png';
import rod from '../assets/Team/Rod.png';
import rog from '../assets/Team/Rog.png';
import andre from '../assets/Team/andre.png';
import ref from '../assets/Team/ref.png';
import aj from '../assets/Team/aj.png';
import gd from '../assets/Team/jd.png';

import Loading from '../components/Loading';


const Section = styled.section`
            min-height: 100vh;
            background-image: url(${bg});
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            @media (max-width: 70em){
              min-height: auto;
              background-size: cover;

            }
`

const Container = styled.div`
              width: 85%;
              margin: 1rem auto;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              flex-wrap: wrap;

              @media (max-width: 64em){
              width: 80%;
              }
              @media (max-width: 48em){
              width: 90%;
              justify-content: center;
              padding-top: 6%;

              }
              `

const Item = styled.div`
            width: calc(20rem - 4vw);
            padding: 1rem 0;
            margin: 2rem 0.1rem;
            position: relative;
            
            z-index:5;
            &:hover{
              img{
                transform: translateY(-2rem) scale(1.2);
              }
            }
            @media (max-width: 30em){
            width: 50vw;
            margin: 1rem 0.1rem;
            width: calc(10rem - 4vw);
            }
            @media (max-width: 700PX){
            width: 50vw;
            margin: 0rem 0.5rem;
            width: calc(10rem - 4vw);
            }

            `

const ImageContainer = styled.div`
              width: 80%;
              margin: 0 auto;
              padding: 1rem;
              cursor: pointer;
              img{
                width: 100%;
                height: auto;
              transition: all 0.3s ease;

              }
`
const Name = styled.h2`
          font-size: ${props => props.theme.h5};
          color: ${props => props.theme.text};
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          margin-top: 1rem;
          backdrop-filter: blur(2px);
          text-align: center;
          @media (max-width: 30em){
            font-size: ${props => props.theme.h6};
            }
;
`

const Position = styled.h2`
            font-size: ${props => props.theme.h6};
            display: flex;
            justify-content: center;
            text-transform: capitalize;
            color: ${props => props.theme.text};
            font-weight:400;
            backdrop-filter: blur(2px);
            text-align: center;
            @media (max-width: 30em){
            font-size: ${props => props.theme.h8};
            }
`
const Image = styled.image`
  width: 200px;      
           
`
const MemberComponent = ({img, name=" ",position=" "}) => {
  return(
    <Item >
      <ImageContainer >
        <img width={400} height={400}  src={img} alt={name}  />
      </ImageContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  )
}


const Team = () => {
  return (
    <Section className='team' id="team">
      
    <img className={'logoTeam'} style={{padding:83}}  src={logo}/>

      <Container>
        <MemberComponent img={andre}  name="AndreG" position="Founder and Producer" />
        <MemberComponent img={rog}  name="Rog" position="Community Manager" />
        <MemberComponent img={rod}  name="Rods" position="NFT artist designer" />
        <MemberComponent img={aj}  name="AJ" position="Blockchain developer" />
        <MemberComponent img={ref}  name="RafaNam" position="Community Manager" />
        <MemberComponent img={gd}  name="@TheJanaNFT" position="Head Community Manager" />        

      </Container>
    </Section>
  )
}

export default Team