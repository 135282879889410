import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Carousel from '../components/Carousel';
import bg from '../assets/Roadmap/Projector.png';
import logo from '../assets/Roadmap/MISSION.png';
import { useInView } from 'react-intersection-observer';




const Roadmap = () => {
  const { ref: magicSectionRef, inView: magicSectionIsVisible } = useInView();



  return (
    <div>
   <div id="roadmap"   className={magicSectionIsVisible? 'roadmap' : 'roadmap2'} style={{backgroundImage: `url(${bg}`, backgroundSize: 'cover', backgroundPosition:'center'}} >

    <img ref={magicSectionRef} className={'logoRoadmap'} style={{paddingTop:23}} src={logo}/>
    <div>
          <Carousel/>
    </div>
    </div>
    </div>
  )
}

export default Roadmap