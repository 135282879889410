import React from 'react'
import styled from 'styled-components'
import bg from '../assets/FAQ/Stars-Background-FAQ.png';
import Ship from '../assets/Footer/Ship-Footer.png';
import Logo from '../components/Logo'
import Instagram from '../assets/Instagram.png';
import OpenSea from '../assets/OpenSea.png';
import Twitter from '../assets/Twitter.png';
import Discord from '../assets/Discord.png';


const Section = styled.section`
                width: 100vw;
                height: 35rem;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                @media (max-width: 48em){
                  height: 15rem;
                  flex-direction: column;

                }
                @media (max-width: 972px){
                      img{
                        width: 63rem;
                        height: auto;
                    }}
                @media (max-width: 48em){
                      img{
                        width: 35rem;
                        height: auto;
                    }}
                @media (max-width: 600px){
                      img{
                        width: 28rem;
                        height: auto;
                    }}

                `
const Container = styled.section`
                background-image: url(${bg});
                background-size:cover;
                background-position: center;
         
                `
const IconList = styled.div`
                display: flex;
                align-items: center;
                &>*{
                  padding-right: 0.5rem;
                  transition: all 0.2s ease;
                  &:hover{
                    transform: scale(1.2);
                  }
                }
             `
const Title = styled.h3`
            align-items: center;
            color: #ffffff;
            font-size: ${props => props.theme.h5};
            font-family: 'VT323', 'arial';
            text-shadow: 1px 1px 9px black, 0 0 2em black, 0 0 0.2em black;
            padding-bottom: 2%;
            
`
const ImgContainer = styled.div`
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 35%;
                    transform: translate(-50%,-50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 6rem;
                        height: auto;
                    }

                    @media (max-width: 48em){
                      img{
                        width: 3rem;
                    }}
`



const Banner = () => {
  return (
      <Container>
    <Section>
     <img  className={'Ship'} src={Ship}/>
     <ImgContainer>
     <IconList>
                <a href="http://Opensea.io" target='_blank'>
                <img  width={30} height={30} src={OpenSea} alt="hunter" />
                </a>
                <a href="http://Discord.com" target='_blank'>
                <img  width={30} height={30}src={Discord} alt="hunter" />
                </a>
                <a href="http://Discord.com" target='_blank'>
                <img     width={30} height={30}src={Twitter} alt="hunter" />
                </a>
                <a href="http://Discord.com" target='_blank'>
                <img    width={30} height={30} src={Instagram} alt="hunter" />
                </a>
         </IconList>
         </ImgContainer>
         
    </Section>
    <Title>&copy; {new Date().getFullYear()} Dark Hunters. All rights reserved. </Title>
      </Container>

  )
}

export default Banner