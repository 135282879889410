import React, { useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Zoom } from "swiper";
// import img1 from '../assets/Roadmap/Hologram-Launch.png';
// import img2 from '../assets/Roadmap/Hologram-Missions.png';
// import img3 from '../assets/Roadmap/Hologram-Staking.png';
// import right from '../assets/Roadmap/Right-arrow.png';
// import left from '../assets/Roadmap/Left-arrow.png';
// import img4 from '../assets/Roadmap/Hologram-Loading.png';
// import but1 from '../assets/Roadmap/buttons/Launch.png';
// import but2 from '../assets/Roadmap/buttons/Missions.png';
// import but3 from '../assets/Roadmap/buttons/Staking.png';
// import but4 from '../assets/Roadmap/buttons/Game.png';
import { Parallax } from 'react-parallax';
import MISSION from '../assets/Roadmap/MISSION.png'
import Opening from '../assets/Roadmap/Opening.png'


const Container = styled.div`
width: 70vw;
height: 80vh;

@media (max-width: 70em){
    height: 60vh;
}
@media (max-width: 64em){
    height: 45vh;
    width: 90vw;
}
@media (max-width: 48em){
    height: 45vh;
    width: 90vw;
}
@media (max-width: 30em){
    height: 45vh;
    width: 90vw;
}
`


const Carousel = () => {
    const [image1, setImage1] = useState(true);
    const [image2, setImage2] = useState(false);
    const [image3, setImage3] = useState(false);
    const [image4, setImage4] = useState(false);

  const showImgae1 = () => {
    setImage1(true);
  };
  const showImgae2 = () => {
    setImage2(true);
  };
  const showImgae3 = () => {
    setImage3(true);
  };
  const showImgae4 = () => {
    setImage4(true);
  };
  const off1 = () => { setImage1(false);};
  const off2 = () => { setImage2(false) ;setImage1(false)};
  const off3 = () => { setImage3(false);setImage1(false)};
  const off4 = () => { setImage4(false);setImage1(false)};

  return (

    <Container>
        {/* <div className='butC'>
<img onMouseOver={showImgae1} onMouseOut={off1} className='ImageRoadmap'  src={but1}/>
<img onMouseOver={showImgae2} onMouseOut={off2} className='ImageRoadmap' src={but2}/>
<img className='ImageRoadmap' onMouseOver={showImgae3} onMouseOut={off3} src={but3}/>
<img className='ImageRoadmap' onMouseOver={showImgae4} onMouseOut={off4} src={but4}/>
       </div> */}

<div className='swiper' >
<img className='swiperimg' width={500} height={400}  src={Opening} alt="DH" />

</div>

      
    </Container>
  )
}

export default Carousel