import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { Link } from 'react-router-dom';
import moon from '../assets/City/moon.png'
import Sky from '../assets/City/sky.jpg'
import logo from '../assets/City/logo.png'
import City from '../assets/City/City.png'

const url = 'https://www.external.url/'

const ImageOne = () => {

  return (

    <div>
    <Parallax pages={1.37}>
      <ParallaxLayer
        offset={0}
        speed={0.5}
        factor={2.9}
        style={{
          backgroundImage: `url(${Sky})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      ></ParallaxLayer>
      <ParallaxLayer 
          offset={0}
          speed={0.8}
          factor={2}
          style={{ textAlign: 'center'}}
> <img className='LogoMain' src={logo} /></ParallaxLayer>
      <ParallaxLayer 
          offset={0.7}
          speed={0.5}
          factor={2}
          style={{ textAlign: 'center'}}
>
      <a target='_blank' href="https://mint.darkhuntersnft.com/">
         <button  className='buttong' src={logo}>MINT</button>
      </a>
  
  </ParallaxLayer>

  
<ParallaxLayer
          offset={0.9999}
          speed={1.5}
          factor={1.1}
          style={{
            textAlign: 'center',
          }}
>    <img  className='Moon'src={moon} /></ParallaxLayer>
<ParallaxLayer
    offset={0.9999}
    speed={1}
    factor={1.1}
    style={{ textAlign: 'center'}}
>  <img src={City} className={'City'} /></ParallaxLayer>


    </Parallax>



    
  </div>
);
}

export default ImageOne